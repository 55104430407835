import type { IDialogContent } from '~/@types/dialog-content';
import { useSecureSessionPost } from '~/composables/dataFetching/genericFetchers';
import { useSessionStore } from '~/stores/useSessionStore';
import { useUserAddress } from '~/stores/useUserAddress';
import { useUserContext } from '~/stores/useUserContext';
import { UseStateKeys } from '~/useStateKeys';
import { handleLoadingError } from '~/utils/handleLoadingError';

export type ProductRequestContactData = {
  officename: string;
  zipCity: {
    zip: string;
    city: string;
  };
  title?: string;
  firstname: string;
  lastname: string;
  email: string;
  checkboxCallMe: boolean;
  phone?: {
    phoneCountry: string;
    phoneNumber: string;
    fullPhoneNumber: string;
  };
  message?: string;
};

export async function useProductRequestContactData() {
  const { t } = useTrans();

  const userContext = useUserContext();
  const userAddresses = useUserAddress();
  const session = useSessionStore();

  const site = useSiteIdent();
  const showDialog = ref(false);
  const isLoading = ref(false);

  const contactData = useState<ProductRequestContactData>(
    UseStateKeys.PRODUCT_REQUEST_CONTACT_DATA,
    () => {
      return getBlankContactData();
    },
  );

  const dialogContent: IDialogContent = {
    dialogHeadline: t('forms.dialogContent.successHeader'),
    dialogText: t('shop.dialog.productRequest.successText'),
    dialogType: 'success',
  };

  const canSubmit = computed(
    () =>
      contactData.value.email !== '' &&
      contactData.value.firstname !== '' &&
      contactData.value.lastname !== '' &&
      contactData.value.officename !== '' &&
      contactData.value.zipCity.zip !== '' &&
      contactData.value.zipCity.city !== '',
  );

  function updateContactData(formData: ProductRequestContactData) {
    contactData.value.officename = formData.officename;
    contactData.value.zipCity.zip = formData.zipCity.zip;
    contactData.value.zipCity.city = formData.zipCity.city;
    contactData.value.title = formData.title ?? '';
    contactData.value.firstname = formData.firstname;
    contactData.value.lastname = formData.lastname;
    contactData.value.email = formData.email;
    contactData.value.checkboxCallMe = formData.checkboxCallMe;
    contactData.value.phone.fullPhoneNumber =
      formData.phone?.fullPhoneNumber ?? '';
    contactData.value.phone.phoneNumber = formData.phone?.phoneNumber ?? '';
  }

  function getDisplayData() {
    return [
      contactData.value.officename,
      `${contactData.value.firstname} ${contactData.value.lastname}`,
      contactData.value.email,
      `${contactData.value.zipCity.zip} ${contactData.value.zipCity.city}`,
    ].join(', ');
  }

  async function submitProductRequest(productId: string) {
    isLoading.value = true;

    const postBody = {
      firstName: contactData.value.firstname,
      lastName: contactData.value.lastname,
      company: contactData.value.officename,
      email: contactData.value.email,
      phone: contactData.value.phone.fullPhoneNumber ?? '',
      message: contactData.value.message ?? '',
      zipcode: contactData.value.zipCity.zip,
      city: contactData.value.zipCity.city,
      title: contactData.value.title ?? '',
      callMe: contactData.value.checkboxCallMe,
    };
    try {
      const result = await useSecureSessionPost(
        `/api/${site}/shop/product/${productId}/productRequest`,
        postBody,
      );
      if (!result) {
        //when error -> no productRequest is send
        dialogContent.dialogText = t('forms.dialogContent.submitError.text');
        dialogContent.dialogHeadline = t(
          'forms.dialogContent.submitError.headline',
        );
        dialogContent.dialogType = 'error';
        showDialog.value = true;
        isLoading.value = false;
        return;
      }
    } catch (e: any) {
      dialogContent.dialogText = t('forms.dialogContent.submitError.text');
      dialogContent.dialogHeadline = t(
        'forms.dialogContent.submitError.headline',
      );
      dialogContent.dialogType = 'error';
      showDialog.value = true;
      isLoading.value = false;
      handleLoadingError(e);
      return;
    }

    //reset/init contactData after submit
    if (session.isLoggedIn) {
      contactData.value.message = '';
    } else {
      contactData.value = getBlankContactData();
    }
    showDialog.value = true;
    isLoading.value = false;
  }

  return {
    isLoading,
    contactData,
    dialogContent,
    showDialog,
    canSubmit,
    submitProductRequest,
    updateContactData,
    getDisplayData,
    getBlankContactData,
    getUserContactData,
    session,
    userContext,
    userAddresses,
  };
}

function getBlankContactData() {
  return {
    officename: '',
    zipCity: {
      zip: '',
      city: '',
    },
    title: '',
    firstname: '',
    lastname: '',
    email: '',
    checkboxCallMe: false,
    phone: {
      phoneCountry: '',
      phoneNumber: '',
      fullPhoneNumber: '',
    },
    message: '',
  };
}

async function getUserContactData() {
  if (!useShopEnabled()) return getBlankContactData();
  const userContext = useUserContext();
  const userAddresses = useUserAddress();
  await userContext.loadAccountData();
  await userContext.loadAddresses();

  return {
    officename: [userContext.userData?.name1, userContext.userData?.name2]
      .filter((c) => !!c)
      .join(' '),
    zipCity: {
      zip: userAddresses.contactAddress?.zip ?? '',
      city: userAddresses.contactAddress?.city ?? '',
    },
    title: userContext.accountData?.title ?? '',
    firstname: userContext.accountData?.firstName ?? '',
    lastname: userContext.accountData?.lastName ?? '',
    email: userContext.userData?.email ?? '',
    checkboxCallMe: false,
    phone: {
      ...getPhoneObject(userContext.accountData?.phoneNumber || ''),
      fullPhoneNumber: userContext.accountData?.phoneNumber ?? '',
    },
    message: '',
  };
}

const getPhoneObject = (phone: string) => {
  if (phone.startsWith('+')) {
    return {
      phoneCountry: phone.slice(0, 3),
      phoneNumber: phone.slice(3),
    };
  }
  return {
    phoneCountry: undefined,
    phoneNumber: phone,
  };
};
